import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="About us | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="About us" lang="cs" />
    <meta name="keywords" content="about" lang="cs" />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  const img = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    img: {
      width: "150px",
    },
  };
  return (
    <Layout>
      <Container>
        <Typography variant="h1" sx={{ paddingBottom: "60px" }}>
          Department of geoinformatics, CDV – Transport Research Centre, Olomouc branch
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={3}>
            <Box>
              <StaticImage src="../images/tag-map-5.png" alt="tag map 5" />
            </Box>
          </Grid>
          <Grid item md={9}>
            <Box id="about-us">
              <Typography variant="body1">
                The Department of Geoinformatics is devoted to GIS-oriented research with a respect
                to transportation. We conduct research in transportation networks robustness,
                vulnerability and resilience, spatiotemporal analyses of traffic crash data, online
                analyses and visualization of transportation data and study of impacts of natural
                processes to transportation infrastructure.
              </Typography>

              <Typography variant="body1" sx={img}>
                We are developers of{" "}
                <Link to="http://kdeplus.cz/en/">
                  <StaticImage alt="" src="../images/kdeplus.png" />
                </Link>
                and{" "}
                <Link to="https://roca.cdvinfo.cz/">
                  <StaticImage alt="" src="../images/roca.jpg" />
                </Link>{" "}
                software.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}
